import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL;
const API = {
  getQuechuaMap() {
    return axios.get('img/quechua_variants.svg');
  },
  getDictionariesByType() {
    return axios.get(`${baseURL}dictionaries/types`);
  },
  getRandomEntries(params) {
    return axios.post(`${baseURL}words/random`, params);
  },
};

export default API;
