<template>
    <span>
      Oportunidades:
        <v-icon  :key="life" v-for="life in lives" color="#ccc194" class="mr-1">mdi-star</v-icon>
        <v-icon  :key="`loss-${loss}`" v-for="loss in losses" color="gray" class="mr-1">
          mdi-star
        </v-icon>
    </span>

</template>

<script>
export default {
  name: 'GameLifeCounter',
  props: ['lives'],
  computed: {
    losses() {
      return 5 - this.lives;
    },
  },
};
</script>

<style scoped>

</style>
