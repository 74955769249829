<template>
  <Game v-if="gameSelected"/>
  <GameConfig v-else/>
</template>

<script>
import Game from './game/Game.vue';
import GameConfig from './game/GameConfig.vue';

export default {
  name: 'Main',
  components: { Game, GameConfig },
  beforeCreate() {
    this.$store.dispatch('dictionaries/loadDictionaries');
  },
  computed: {
    gameSelected() {
      return this.$store.state.game.gameSelected;
    },
  },
};
</script>

<style scoped>

</style>
