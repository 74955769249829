<template>
  <div class="my-5 text-center">
    <v-card elevation="2" color="info" dark outlined class="mb-3">
      <v-card-title class="text-capitalize">
        <p class="text-h5 text-sm-h2 flex-grow-1">{{ currentQuestion.questionEntry }}</p>
      </v-card-title>
      <v-card-subtitle>
        <p class="text-subtitle-1 text-sm-h5">{{ currentQuestion.questionDictionary }}</p>
      </v-card-subtitle>
    </v-card>
    <v-row :key="rowIndex" v-for="(row, rowIndex) in currentQuestionRows" align="stretch">
      <v-col :key="index" v-for="(option, index) in row" class="d-flex" cols="6" sm="6">
        <v-card class="question-option"
                elevation="2"
                outlined
                @click="selectResponse(option)">
        <v-card-text v-html="definitionOnly(option)">
        </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <GameAnswerNotification v-if="this.$vuetify.breakpoint.xsOnly"/>
  </div>
</template>

<script>
import GameAnswerNotification from './GameAnswerNotification.vue';

export default {
  name: 'GameRound',
  components: { GameAnswerNotification },
  computed: {
    game() {
      return this.$store.state.game.game;
    },
    currentQuestion() {
      return this.game.currentQuestion;
    },
    currentQuestionRows() {
      if (this.currentQuestion) {
        return [this.currentQuestion.options.slice(0, 2), this.currentQuestion.options.slice(2)];
      }
      return [];
    },
  },
  methods: {
    async selectResponse(option) {
      await this.$store.dispatch('game/selectResponse', option);
    },
    definitionOnly(option) {
      let result = option.meaning;
      if (option.meaning.includes('<br>')) {
        result = option.meaning.substring(0, option.meaning.indexOf('<br>'));
      }
      if (option.meaning.startsWith('[')) {
        result = option.meaning.substring(option.meaning.indexOf(']') + 1);
      }
      return result.replace(/\(&lt;.*?\)\s+/, '');
    },
  },
};
</script>

<style scoped lang="scss">
  .question-option {
    cursor: pointer;
    flex: 50%
  }

</style>
