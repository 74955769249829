import API from '../api';

const DEFAULT_DICT_LANG = 'qu-es';

const langs = {
  qu: 'Quechua',
  es: 'Español',
  en: 'Inglés',
  fr: 'Francés',
  it: 'Italiano',
  de: 'Alemán',
  ru: 'Ruso',
};

const langOrder = [
  'qu-es',
  'es-qu',
  'qu-en',
  'en-qu',
  'qu-fr',
  'fr-qu',
  'qu-de',
  'de-qu',
  'qu-it',
  'it-qu',
  'qu-ru',
  'ru-qu',
];

export default {
  namespaced: true,
  state: {
    selectedLang: DEFAULT_DICT_LANG,
    dictionariesByLang: [],
  },
  getters: {
    dictTypes: (state) => {
      if (!state.dictionariesByLang) return [];
      const dictLang = state.dictionariesByLang
        .find((dictByLang) => dictByLang.langCode === state.selectedLang);
      return dictLang ? dictLang.quechuaVariants : [];
    },
  },
  mutations: {
    updateSelectedLang(state, lang) {
      state.selectedLang = lang;
    },
    setDictionariesByLang(state, dictionariesByLang) {
      state.dictionariesByLang = dictionariesByLang
        .map((dictByLang) => {
          const langName = `${langs[dictByLang.languageBegin]} - ${langs[dictByLang.languageEnd]}`;
          return Object.assign(dictByLang, { langName });
          // eslint-disable-next-line max-len
        }).sort((lang1, lang2) => langOrder.indexOf(lang1.langCode) - langOrder.indexOf(lang2.langCode));
    },
    resetSelectedLang(state) {
      state.selectedLang = DEFAULT_DICT_LANG;
      state.dictionariesByLang.forEach((dictLang) => {
        // eslint-disable-next-line no-param-reassign,no-return-assign
        dictLang.quechuaVariants.forEach((variant) => variant.selected = true);
      });
    },
  },
  actions: {
    getQuechuaMap() {
      return API.getQuechuaMap();
    },
    async loadDictionaries(context) {
      try {
        const { data } = await API.getDictionariesByType();
        context.commit('setDictionariesByLang', data);
      } catch (e) {
        context.commit('setDictionariesByLang', []);
      }
    },
    async fetchRandomEntries({ getters, commit, state }) {
      commit('game/toggleGameLoading', null, { root: true });

      const variantIds = getters.dictTypes
        .filter((variant) => variant.selected)
        .map((variant) => variant.id);
      const [languageBegin, languageEnd] = state.selectedLang.split('-');
      const { data } = await API.getRandomEntries({ variantIds, languageBegin, languageEnd });
      commit('game/addGameWords', data, { root: true });
      commit('game/toggleGameLoading', null, { root: true });
    },
  },
};
