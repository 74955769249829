<template>
  <v-row>
    <v-col class="text-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'GameLoading',
};
</script>

<style scoped>

</style>
