// min and max included
const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

class GameQuestion {
  constructor(questionItems) {
    this.question = questionItems[randomIntFromInterval(0, questionItems.length - 1)];
    this.options = questionItems;
    this.responseGiven = '';
    this.correct = false;
  }

  get questionEntry() {
    return this.question.word;
  }

  get questionDictionary() {
    return `${this.question.dictionaryName || ''} - ${this.question.dictionaryAuthor || ''}`;
  }

  set response(response) {
    this.responseGiven = response;
    this.correct = this.responseGiven.id === this.question.id;
  }
}

export default GameQuestion;
