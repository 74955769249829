import Vue from 'vue';
import Vuex from 'vuex';
import dictionaryModule from './dictionaries-module';
import gameModule from './game-module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackbar: { active: false, color: '', message: '' },
  },
  mutations: {
    showCurrentQuestionNotif(state, snackbar) {
      state.snackbar = snackbar;
    },
  },
  actions: {
    resetGame({ commit }) {
      commit('game/resetGame');
      commit('dictionaries/resetSelectedLang');
    },
  },
  modules: {
    dictionaries: dictionaryModule,
    game: gameModule,
  },
});
