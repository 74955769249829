<template>
  <v-snackbar v-model="snackbar.active" :timeout="2000" :color="snackbar.color" outlined text>
    {{ snackbar.message}}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar.active = false">
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GameAnswerNotification',
  computed: {
    ...mapState(['snackbar']),
  },
};
</script>

<style scoped>

</style>
