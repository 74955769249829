<template>
  <v-app-bar
    app
    color="white"
    elevation="1"
    hide-on-scroll
  >
    <div class="d-flex align-center">
      <v-img
        alt="Qichwa 2.0"
        class="shrink mr-2"
        contain
        src="/img/qichwa20-logo.png"
        transition="scale-transition"
        width="100"
      />
    </div>
    <v-app-bar-title class="text-center mx-auto">
      Qichwa 2.0
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <template v-if="isMobile">
      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn text color="primary" href="https://www.qichwa.net" target="_blank norel" rel="noopener noreferrer">
                Qichwa 2.0
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn text color="primary" href="https://www.dic.qichwa.net" target="_blank norel" rel="noopener noreferrer">
                QichwaDic
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-else>
      <v-btn text color="primary" href="https://www.qichwa.net" target="_blank norel" rel="noopener noreferrer">
        Qichwa 2.0
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn text color="primary" href="https://www.dic.qichwa.net" target="_blank norel" rel="noopener noreferrer">
        QichwaDic
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>

export default {
  name: 'Nav',
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style scoped>
    nav {
        padding: 5px;
    }

    .navbar-brand {
        padding: 0;
    }

    .navbar-brand>img{
        max-height: 64px;
        padding: 5px;
    }
</style>
