import GameQuestion from './game-question';

const WORDS_PER_TURN = 4;
const MAX_SCORE = 20;

const sliceGameWords = (words, len) => {
  const chunks = [];
  let current = 0;
  while (current < words.length) {
    chunks.push(new GameQuestion(words.slice(current, current += len)));
  }
  return chunks;
};

class Game {
  constructor() {
    this.lives = 5;
    this.score = 0;
    this.loading = false;
    this.playing = true;
    this.finished = false;
    this.won = false;
    this.questions = [];
    this.answeredQuestions = [];
    this.currentQuestion = null;

    this.addWords = (words) => {
      Object.assign(this, { questions: sliceGameWords(words, WORDS_PER_TURN) });
    };

    this.addResponse = (option) => {
      this.currentQuestion.response = option;
      if (this.currentQuestion.correct) {
        this.score += 1;
      } else {
        this.lives -= 1;
      }
      this.answeredQuestions.push(this.currentQuestion);
      if (this.score === MAX_SCORE) {
        this.won = true;
        this.playing = false;
        this.finished = true;
      } else if (this.lives === 0) {
        this.playing = false;
        this.finished = true;
      }
    };

    this.nextQuestion = () => {
      this.currentQuestion = this.questions.shift();
    };

    this.hasQuestions = () => this.questions.length > 0;
  }

  get isActive() {
    return this.playing && !this.finished;
  }

  get hasAnsweredQuestions() {
    return this.answeredQuestions.length > 0;
  }
}

export default Game;
