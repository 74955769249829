<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <GameInfoSnackbar/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" sm="8" md="6" lg="4" class="mx-auto text-center">
        <v-select v-model="selectedLang"
                  :items="this.dictionariesByLang"
                  item-value="langCode"
                  item-text="langName"
                  class="d-inline-block mx-1"
                  solo>
        </v-select>
        <v-btn @click="startGame" color="primary" large>Empezar</v-btn>
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col cols="10" sm="8" md="6" lg="4" class="mx-auto">
        <QuechuaMap v-if="this.quechuaMap" :quechua-map="this.quechuaMap"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuechuaMap from './QuechuaMap.vue';
import GameInfoSnackbar from './GameInfoSnackbar.vue';

export default {
  name: 'GameConfig',
  components: { GameInfoSnackbar, QuechuaMap },
  data() {
    return {
      quechuaMap: null,
    };
  },
  async created() {
    this.quechuaMap = (await this.$store.dispatch('dictionaries/getQuechuaMap')).data;
  },
  computed: {
    selectedLang: {
      get() {
        return this.$store.state.dictionaries.selectedLang;
      },
      set(lang) {
        this.$store.commit('dictionaries/updateSelectedLang', lang);
      },
    },
    dictionariesByLang() {
      return this.$store.state.dictionaries.dictionariesByLang;
    },
  },
  methods: {
    startGame() {
      this.$store.dispatch('game/startGame');
    },
  },
};
</script>

<style scoped>
</style>
