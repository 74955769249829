<template>
  <!-- Created by Uwe Dedering -->
  <div id="container" ref="container"></div>
</template>

<script>
import { SVG } from '@svgdotjs/svg.js';

export default {
  name: 'QuechuaMap',
  props: ['quechuaMap'],
  data() {
    return {
      quechuaVariants: {
        QI: null,
        'QII-A': null,
        'QII-B': null,
        'QII-C': null,
      },
      variantsGroup: null,
      variantsTextGroup: null,
    };
  },
  async mounted() {
    const buildSvg = (id) => {
      const mapSvg = SVG(`#${id}`);
      mapSvg.children()
        .click(() => this.toggleQuechuaVariantSelection(id))
        .mouseover(() => this.onMouseOver(id))
        .mouseout(() => this.onMouseOut());
      const textSvg = SVG(`#g-text-${id}`)
        .click(() => this.toggleQuechuaVariantSelection(id))
        .mouseover(() => this.onMouseOver(id))
        .mouseout(() => this.onMouseOut());
      return { mapSvg, textSvg };
    };
    const draw = SVG().addTo(this.$refs.container);
    draw.svg(this.quechuaMap, true);
    this.variantsGroup = SVG('#variants-group');
    this.variantsTextGroup = SVG('#group-variants-text');
    this.quechuaVariants.QI = buildSvg('QI');
    this.quechuaVariants['QII-A'] = buildSvg('QII-A');
    this.quechuaVariants['QII-B'] = buildSvg('QII-B');
    this.quechuaVariants['QII-C'] = buildSvg('QII-C');
  },
  computed: {
    dictionaryTypes() {
      return this.$store.getters['dictionaries/dictTypes'];
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    dictionaryTypes(newTypes) {
      const varietiesWithVisibility = Object.keys(this.quechuaVariants).map((key) => (
        {
          code: key,
          selected: newTypes.some((type) => type.code === key && type.selected),
        }));
      varietiesWithVisibility.forEach((variety) => {
        this.toggleBlockedState(variety);
      });
    },
  },
  methods: {
    toggleQuechuaVariantSelection(variantId) {
      this.dictionaryTypes
        .filter((dictType) => dictType.code === variantId)
        .forEach((variant) => {
          this.$set(variant, 'selected', !variant.selected);
          this.toggleBlockedState(variant);
        });
    },
    toggleBlockedState(variant) {
      const mapContainer = this.quechuaVariants[variant.code];
      if (variant.selected) {
        mapContainer.mapSvg.children().removeClass('path-blocked');
        mapContainer.textSvg.children().removeClass('path-blocked');
      } else {
        mapContainer.mapSvg.children().addClass('path-blocked');
        mapContainer.textSvg.children().addClass('path-blocked');
      }
    },
    onMouseOver(variantId) {
      if (this.dictionaryTypes.some((dictType) => dictType.code === variantId)) {
        this.quechuaVariants[variantId].mapSvg.children().addClass('path-hovered');
        this.quechuaVariants[variantId].textSvg.find('rect').addClass('rect-hovered');
      }
    },
    onMouseOut() {
      this.variantsGroup.find('g path').removeClass('path-hovered');
      this.variantsTextGroup.find('g rect').removeClass('rect-hovered');
    },
  },
};
</script>

<style>
  #container svg {
    border: 1px solid lightgray;
    border-radius: 10px;
  }

  path[id^=path-quechua-]:hover, rect[id^=rect-Q]:hover{
    cursor: pointer;
  }

  .path-hovered {
    stroke: #cce15e !important;
    stroke-width: 8px;
  }

  .rect-hovered {
    stroke: gray !important;
    stroke-width: 8px;
  }

  path.path-blocked {
    opacity: 0.2 !important;
  }

  rect.path-blocked {
    opacity: 0.5 !important;
  }
</style>
