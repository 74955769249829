<template>
  <div class="text-center">
    <v-btn
      large
      dark
      color="indigo"
      @click="snackbar = true"
    >
      ¿Cómo jugar?
    </v-btn>
    <v-snackbar
      v-model="snackbar"
      multi-line
      timeout="-1"
    >
      Primero, elige los idiomas y las variantes de quechua
      <strong>seleccionándolos en el mapa.</strong>
      <br><br>
      Por cada turno, se te preguntará el significado de una palabra o frase.
      Podrás escoger tu respuesta dentro de las cuatro alternativas presentadas.
      Podrás jugar tanto como  puedas hasta llegar a 20 puntos o no tener más estrellas
      jugar.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="indigo"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'GameInfoSnackbar',
  data() {
    return {
      snackbar: false,
    };
  },
};
</script>

<style scoped>

</style>
