<template>
  <div class="container">
    <v-row class="my-2">
      <v-col>
        <v-card>
          <v-card-title class="justify-center justify-space-between">
            <GameLifeCounter :lives="game.lives"/>
            <span>Puntos: {{ game.score }}</span>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <GameLoading v-if="game.loading"/>
    <template v-else>
      <v-row class="text-center">
        <v-col>
          <ResetGameBtn/>
        </v-col>
        <v-col v-if="!showStats && game.finished && game.hasAnsweredQuestions">
          <v-btn color="info" @click="openStats" large>Ver respuestas</v-btn>
        </v-col>
      </v-row>
      <Stats v-if="showStats"/>
      <template v-else>
        <GameRound v-if="game.isActive && game.currentQuestion"/>
        <template v-else-if="game.finished">
          <GameResultMessage :success="game.won"/>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import GameRound from './GameRound.vue';
import GameLifeCounter from './GameLifeCounter.vue';
import Stats from '../stats/Stats.vue';
import ResetGameBtn from '../common/ResetGameBtn.vue';
import GameResultMessage from './GameResultMessage.vue';
import GameLoading from './GameLoading.vue';

export default {
  name: 'Game',
  components: {
    GameLoading, GameResultMessage, ResetGameBtn, Stats, GameLifeCounter, GameRound,
  },
  async mounted() {
    await this.$store.dispatch('dictionaries/fetchRandomEntries');
    await this.$store.dispatch('game/nextQuestion');
  },
  data() {
    return {
      showStats: false,
    };
  },
  computed: {
    game() {
      return this.$store.state.game.game;
    },
  },
  methods: {
    openStats() {
      this.showStats = !this.showStats;
    },
  },
};
</script>

<style>
</style>
