import Game from './game';

export default {
  namespaced: true,
  state: {
    gameSelected: false,
    game: null,
  },
  mutations: {
    addGameWords(state, words) {
      if (words && words.length > 0) {
        state.game.addWords(words);
      }
    },
    nextQuestion(state) {
      state.game.nextQuestion();
    },
    setResponse(state, option) {
      state.game.addResponse(option);
    },
    startGame(state) {
      state.game = new Game();
      state.gameSelected = true;
    },
    resetGame(state) {
      state.gameSelected = false;
      state.game = null;
    },
    toggleGameLoading(state) {
      state.game.loading = !state.game.loading;
    },
  },
  actions: {
    async startGame(context) {
      context.commit('startGame');
    },
    async nextQuestion({ commit, dispatch, state }) {
      if (!state.game.hasQuestions()) {
        await dispatch('fetchRandomEntries');
      }
      commit('nextQuestion');
    },
    async selectResponse({ state, commit, dispatch }, option) {
      commit('setResponse', option);
      const message = state.game.currentQuestion.correct ? '¡Muy bien!' : '¡Ups, incorrecto! ';
      const color = state.game.currentQuestion.correct ? 'blue-grey' : 'red';
      commit('showCurrentQuestionNotif', { active: true, message, color }, { root: true });
      if (state.game.isActive) {
        await dispatch('nextQuestion');
      }
    },
  },
};
