<template>
  <v-footer>
    <div class="footer mx-auto">
      © 2018 - 2020
      <v-btn text color="primary" href="https://www.qichwa.net" target="_blank norel" rel="noopener noreferrer">
        Qichwa 2.0
      </v-btn>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
    .footer {
        padding: 2.5rem;
        text-align: center;
    }
</style>
