<template>
  <v-app>
    <Nav/>
    <v-main>
      <Main/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Main from './components/Main.vue';
import Footer from './components/common/Footer.vue';
import Nav from './components/common/Nav.vue';

export default {
  name: 'App',

  components: {
    Nav,
    Footer,
    Main,
  },
};
</script>
