<template>
  <v-btn color="error" large @click="$store.commit('game/resetGame')">
    Reiniciar juego
  </v-btn>
</template>

<script>
export default {
  name: 'ResetGameBtn',
};
</script>

<style scoped>

</style>
