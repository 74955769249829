<template>
  <v-container class="my-3">
    <h1 class="text-center">Estadísticas del juego</h1>
    <v-row class="mt-3">
      <v-col cols="12" sm="10" class="mx-auto">
        <v-expansion-panels accordion multiple v-model="this.defaultOpenAnswer">
          <v-expansion-panel :key="index" v-for="(answer, index) in game.answeredQuestions">
            <v-expansion-panel-header class="text-capitalize" :disable-icon-rotate="answer.correct">
              {{ answer.questionEntry }}
              <template v-slot:actions>
                <v-icon v-if="answer.correct" color="primary">
                  mdi-check
                </v-icon>
                <v-icon v-else color="error">
                  mdi-alert-circle
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-alert color="success" dark outlined>
                <p>Respuesta correcta</p>
                <p v-html="answer.question.meaning"></p>
              </v-alert>
              <v-alert :type="answer.correct ? 'success' : 'error'"
                       dark
                       outlined>
                <p>Respuesta elegida</p>
                <p v-html="answer.responseGiven.meaning"></p>
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Stats',
  data() {
    return {
      defaultOpenAnswer: [0],
    };
  },
  computed: {
    game() {
      return this.$store.state.game;
    },
  },
};
</script>

<style scoped>

</style>
