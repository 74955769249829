<template>
  <v-container class="text-center">
    <v-row>
      <v-col>
        <v-alert :type="gameResult.alertType" class="mt-3" outlined dense :icon="false">
          <h1>{{ gameResult.alertTitle }}</h1>
          <p>
            {{ gameResult.alertMessage }}
          </p>
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col cols="10" sm="3" class="mx-auto">
        <v-img :src="gameResult.imageSrc" :alt="gameResult.imageAlt"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'GameResultMessage',
  props: ['success'],
  computed: {
    gameResult() {
      if (this.success) {
        return {
          alertType: 'success',
          alertTitle: 'Haylli!',
          alertMessage: '¡Bravo! Pudiste llegar a 20 puntos sin perder todas tus estrellas.',
          imageSrc: 'https://upload.wikimedia.org/wikipedia/commons/f/f0/Nueva_cor%C3%B3nica_y_buen_gobierno_%281936_facsimile%29_p360.png',
          imageAlt: 'Quipucamayoc',
        };
      }
      return {
        alertType: 'error',
        alertTitle: 'Llakipaqmi!',
        alertMessage: '¡Qué pena! Se agotaron las estrellas para este juego, pero puedes seguir intentándolo.',
        imageSrc: 'https://media1.tenor.com/images/475ddffbe83ef9364cf0f3d480b6df96/tenor.gif',
        imageAlt: 'Llakipaqmi',
      };
    },
  },
};
</script>

<style scoped>

</style>
